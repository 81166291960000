/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from "react";
import { Switch, Route } from "react-router-dom";
import CreateTemplate from "containers/SmsTemplates/components/ModalContent/create";
import EditTemplate from "containers/SmsTemplates/components/ModalContent/edit";
import TestTemplate from "containers/SmsTemplates/components/ModalContent/test";
import ProspectInformationModal from "containers/ProspectInformationModal";
import ChangeProspectStatus from "containers/ChangeProspectStatusFormModal";
import ChangeMemberStatus from "containers/ChangeMemberStatusFormModal";
import TestModal from "containers/MemberProfile/components/TestModal";
import MemberInformation from "containers/MemberInformationModal";
import { Rewards } from "containers/MemberProfile/components/Rewards";
import ChangePauseStatus from "containers/ChangePauseStatusModal";
import EditReminder from "containers/EditReminderFormModal";
import CheckIns from "containers/ReviewTaskCalendar";
import Appointments from "containers/ReviewAppointment";
import DeleteMember from "containers/DeleteMemberModal";
import EditMemberProfileModal from "containers/EditMemberProfileFormModal";
import EditProspectProfileModal from "containers/EditProspectProfileFormModal";
import EditEmailAddressModal from "containers/EditEmailAddressModal";
import CreateEmailAddressModal from "containers/CreateEmailAddressModal";
import EditNameModal from "containers/EditNameModal";
import CreateNameModal from "containers/CreateNameModal";
import EditPhoneNumberModal from "containers/EditPhoneNumberModal";
import CreatePhoneNumberModal from "containers/CreatePhoneNumberModal";
import EditMailingAddressModal from "containers/EditMailingAddressModal";
import CreateMailingAddressModal from "containers/CreateMailingAddressModal";
import { ContactType } from "types/globalTypes";
import { ReminderMethod } from "components/EditReminderForm/inputs";
import { ManualCreditModal } from "containers/ManualCreditModal";
import { CreateBonusBehaviorModal } from "containers/CreateBonusBehaviorModal";
import { EditPaymentOptionModal } from "containers/EditPaymentOptionModal";
import { EditProgramFormModal } from "containers/EditProgramFormModal";
import { EditPauseFormModal } from "containers/EditPauseFormModal";
import { ProgramInfoFormModal } from "containers/ProgramInfoFormModal";
import { ReOpenProspectModal } from "containers/ReOpenProspectModal";
import { CreateDialysisApptFormModal } from "components/CreateDialysisApptFormModal";
import DeleteDialysisApptFormModal from "components/DeleteDialysisApptFormModal";

export default () => (
  <Switch>
    <Route
      path="/acquisition/edit/:id"
      component={({
        location: {
          state: { template },
        },
        history: { goBack },
      }) => <EditTemplate template={template} goBack={goBack} />}
    />
    <Route
      path="/acquisition/test/:id"
      component={({
        location: {
          state: { template },
        },
        history: { goBack },
      }) => <TestTemplate template={template} goBack={goBack} />}
    />
    <Route
      path="/acquisition/create"
      component={({ history: { goBack } }) => (
        <CreateTemplate goBack={goBack} />
      )}
    />
    <Route
      path="/prospect/:id/info"
      component={({
        history: { goBack, push },
        location: {
          state: { adminRole },
        },
        match: {
          params: { id },
        },
      }) => (
        <ProspectInformationModal
          id={id}
          goBack={goBack}
          adminRole={adminRole}
          routeEditEmailAddress={(emailId) =>
            push({
              pathname: `/prospect/${id}/edit/email/${emailId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateEmailAddress={() =>
            push({
              pathname: `/prospect/${id}/create/email/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditName={(nameId) =>
            push({
              pathname: `/prospect/${id}/edit/name/${nameId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateName={() =>
            push({
              pathname: `/prospect/${id}/create/name/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditMailingAddress={(mailingAddressId) =>
            push({
              pathname: `/prospect/${id}/edit/address/${mailingAddressId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateMailingAddress={() =>
            push({
              pathname: `/prospect/${id}/create/address/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditPhoneNumber={(phoneNumberId) =>
            push({
              pathname: `/prospect/${id}/edit/phoneNumber/${phoneNumberId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreatePhoneNumber={() =>
            push({
              pathname: `/prospect/${id}/create/phoneNumber/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditProfile={() =>
            push({
              pathname: `/prospect/${id}/edit/profile`,
              state: {
                isModalOpen: true,
              },
            })
          }
        />
      )}
    />
    <Route
      path="/prospect/:id/actions/status"
      component={({
        location: {
          state: {
            prospect: { shortId, programCode, status },
            adminRole,
          },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <ChangeProspectStatus
          shortId={shortId}
          programCode={programCode}
          id={id}
          status={status}
          goBack={goBack}
          adminRole={adminRole}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/edit/email/:emailId"
      component={({
        history: { goBack },
        match: {
          params: { emailId, prospectId },
        },
      }) => (
        <EditEmailAddressModal
          id={emailId}
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/create/email/"
      component={({
        history: { goBack },
        match: {
          params: { prospectId },
        },
      }) => (
        <CreateEmailAddressModal
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/edit/name/:nameId"
      component={({
        history: { goBack },
        match: {
          params: { nameId, prospectId },
        },
      }) => (
        <EditNameModal
          id={nameId}
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/create/name/"
      component={({
        history: { goBack },
        match: {
          params: { prospectId },
        },
      }) => (
        <CreateNameModal
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/edit/phoneNumber/:phoneNumberId"
      component={({
        history: { goBack },
        match: {
          params: { phoneNumberId, prospectId },
        },
      }) => (
        <EditPhoneNumberModal
          id={phoneNumberId}
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/create/phoneNumber/"
      component={({
        history: { goBack },
        match: {
          params: { prospectId },
        },
      }) => (
        <CreatePhoneNumberModal
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/edit/address/:mailingAddressId"
      component={({
        history: { goBack },
        match: {
          params: { mailingAddressId, prospectId },
        },
      }) => (
        <EditMailingAddressModal
          id={mailingAddressId}
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:prospectId/create/address/"
      component={({
        history: { goBack },
        match: {
          params: { prospectId },
        },
      }) => (
        <CreateMailingAddressModal
          contactId={prospectId}
          contactType={ContactType.Prospect}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/prospect/:id/edit/profile"
      component={({
        history: { goBack },
        match: {
          params: { id },
        },
      }) => <EditProspectProfileModal id={id} goBack={goBack} />}
    />
    <Route
      path="/member/:id/test"
      component={({
        location: {
          state: {
            id,
            isReminderMessagingEnabled,
            isLocalNotificationEnabled,
            isProductTourRecurring,
          },
        },
        history: { goBack },
      }) => (
        <TestModal
          id={id}
          isReminderMessagingEnabled={isReminderMessagingEnabled}
          isLocalNotificationEnabled={isLocalNotificationEnabled}
          isProductTourRecurring={isProductTourRecurring}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:id/information"
      component={({
        location: {
          state: { adminRole },
        },
        history: { push, goBack },
        match: {
          params: { id },
        },
      }) => (
        <MemberInformation
          id={id}
          adminRole={adminRole}
          goBack={goBack}
          routeEditEmailAddress={(emailId) =>
            push({
              pathname: `/member/${id}/edit/email/${emailId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateEmailAddress={() =>
            push({
              pathname: `/member/${id}/create/email/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditName={(nameId) =>
            push({
              pathname: `/member/${id}/edit/name/${nameId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateName={() =>
            push({
              pathname: `/member/${id}/create/name/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditMailingAddress={(mailingAddressId) =>
            push({
              pathname: `/member/${id}/edit/address/${mailingAddressId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreateMailingAddress={() =>
            push({
              pathname: `/member/${id}/create/address/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditPhoneNumber={(phoneNumberId) =>
            push({
              pathname: `/member/${id}/edit/phoneNumber/${phoneNumberId}`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeCreatePhoneNumber={() =>
            push({
              pathname: `/member/${id}/create/phoneNumber/`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditProfile={() =>
            push({
              pathname: `/member/${id}/edit/profile`,
              state: {
                isModalOpen: true,
              },
            })
          }
        />
      )}
    />
    <Route
      path="/member/:memberId/edit/email/:emailId"
      component={({
        history: { goBack },
        match: {
          params: { emailId, memberId },
        },
      }) => (
        <EditEmailAddressModal
          id={emailId}
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/create/email/"
      component={({
        history: { goBack },
        match: {
          params: { memberId },
        },
      }) => (
        <CreateEmailAddressModal
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/edit/name/:nameId"
      component={({
        history: { goBack },
        match: {
          params: { nameId, memberId },
        },
      }) => (
        <EditNameModal
          id={nameId}
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/create/name/"
      component={({
        history: { goBack },
        match: {
          params: { memberId },
        },
      }) => (
        <CreateNameModal
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/edit/phoneNumber/:phoneNumberId"
      component={({
        history: { goBack },
        match: {
          params: { phoneNumberId, memberId },
        },
      }) => (
        <EditPhoneNumberModal
          id={phoneNumberId}
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/create/phoneNumber/"
      component={({
        history: { goBack },
        match: {
          params: { memberId },
        },
      }) => (
        <CreatePhoneNumberModal
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/edit/address/:mailingAddressId"
      component={({
        history: { goBack },
        match: {
          params: { mailingAddressId, memberId },
        },
      }) => (
        <EditMailingAddressModal
          id={mailingAddressId}
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/create/address/"
      component={({
        history: { goBack },
        match: {
          params: { memberId },
        },
      }) => (
        <CreateMailingAddressModal
          contactId={memberId}
          contactType={ContactType.Member}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:id/edit/profile"
      component={({
        history: { goBack },
        match: {
          params: { id },
        },
      }) => <EditMemberProfileModal id={id} goBack={goBack} />}
    />
    <Route
      exact
      path="/member/:id/rewards"
      component={({
        location: {
          state: {
            periodEnd,
            periodReward,
            periodSum,
            isAdmin,
            rewardPeriodId,
            periodStart,
            settleDate,
            pauseStatus,
            programCode,
          },
        },
        match: {
          params: { id },
        },
        history: { goBack, push },
      }) => (
        <Rewards
          memberId={id}
          rewardPeriodId={rewardPeriodId}
          periodEnd={periodEnd}
          periodReward={periodReward}
          periodSum={periodSum}
          goBack={goBack}
          isAdmin={isAdmin}
          periodStart={periodStart}
          settleDate={settleDate}
          pauseStatus={pauseStatus}
          routeManualCredit={() =>
            push({
              pathname: `/member/${id}/rewards/credit`,
              state: {
                isModalOpen: true,
              },
            })
          }
          routeEditPaymentOption={() =>
            push({
              pathname: `/member/${id}/edit/paymentOption`,
              state: {
                isModalOpen: true,
                programCode,
              },
            })
          }
        />
      )}
    />
    <Route
      path="/member/:id/rewards/credit"
      component={({
        history: { goBack },
        match: {
          params: { id },
        },
      }) => <ManualCreditModal goBack={goBack} memberId={id} />}
    />
    <Route
      path="/member/:id/edit/paymentOption"
      component={({
        location: {
          state: { programCode },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <EditPaymentOptionModal
          goBack={goBack}
          memberId={id}
          programCode={programCode}
        />
      )}
    />
    <Route
      path="/member/:id/other"
      component={({
        history: { goBack },
        match: {
          params: { id },
        },
      }) => <ChangePauseStatus id={id} goBack={goBack} />}
    />
    <Route
      path="/member/:id/edit/pause"
      component={({
        history: { goBack },
        location: {
          state: {
            pauseDetails: { id, reason, note },
          },
        },
      }) => (
        <EditPauseFormModal
          goBack={goBack}
          id={id}
          reason={reason}
          note={note}
        />
      )}
    />
    <Route
      path="/member/:id/reminders/create"
      component={({
        location: {
          state: { timezone, adminRole, programTemplate },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <EditReminder
          title="Create new reminder"
          method={ReminderMethod.Create}
          memberId={id}
          timezone={timezone}
          goBack={goBack}
          adminRole={adminRole}
          programTemplate={programTemplate}
        />
      )}
    />
    <Route
      path="/member/:memberId/reminders/:reminderId/edit"
      component={({
        location: {
          state: { timezone, adminRole, programTemplate },
        },
        history: { goBack },
        match: {
          params: { memberId, reminderId },
        },
      }) => (
        <EditReminder
          title="Edit reminder"
          method={ReminderMethod.Edit}
          memberId={memberId}
          reminderId={reminderId}
          timezone={timezone}
          goBack={goBack}
          adminRole={adminRole}
          programTemplate={programTemplate}
        />
      )}
    />
    <Route
      path="/member/:memberId/reminders/:reminderId/info"
      component={({
        location: {
          state: { timezone, adminRole, programTemplate },
        },
        history: { goBack },
        match: {
          params: { memberId, reminderId },
        },
      }) => (
        <EditReminder
          title="Review reminder"
          method={ReminderMethod.Info}
          memberId={memberId}
          reminderId={reminderId}
          timezone={timezone}
          goBack={goBack}
          adminRole={adminRole}
          programTemplate={programTemplate}
        />
      )}
    />
    <Route
      path="/member/:id/check-in"
      component={({
        location: {
          state: { calendarData, timezone },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <CheckIns
          id={id}
          timezone={timezone}
          calendarData={calendarData}
          goBack={goBack}
        />
      )}
    />
    <Route
      path="/member/:id/appointment"
      component={({
        location: {
          state: { calendarData, timezone },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <Appointments
          id={id}
          calendarData={calendarData}
          goBack={goBack}
          timezone={timezone}
        />
      )}
    />
    <Route
      path="/member/:id/actions/status"
      component={({
        location: {
          state: { shortId, programCode, status, adminRole },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <ChangeMemberStatus
          shortId={shortId}
          programCode={programCode}
          status={status}
          id={id}
          goBack={goBack}
          adminRole={adminRole}
        />
      )}
    />
    <Route
      path="/member/:id/actions/delete"
      component={({
        history,
        match: {
          params: { id },
        },
      }) => (
        <DeleteMember
          id={id}
          goHome={() => history.push({ pathname: "/" })}
          goBack={history.goBack}
        />
      )}
    />
    <Route
      path="/:userType/:id/actions/reopen"
      component={({
        history,
        location: {
          state: { programCode, shortId, userType },
        },
        match: {
          params: { id },
        },
      }) => (
        <ReOpenProspectModal
          id={id}
          userType={userType}
          programCode={programCode}
          shortId={shortId}
          routeToProspectPage={(prospectId) =>
            history.push({ pathname: `/prospect/${prospectId}` })
          }
          goBack={history.goBack}
        />
      )}
    />
    <Route
      path="/create/bonus"
      component={({
        history: { goBack },
        location: {
          state: { allBonusBehaviorConfigs },
        },
      }) => (
        <CreateBonusBehaviorModal
          goBack={goBack}
          bonusBehaviorConfigs={allBonusBehaviorConfigs}
        />
      )}
    />
    <Route
      path="/programs/:id/edit"
      component={({
        location: {
          state: { program },
        },
        history: { goBack },
        match: {
          params: { id },
        },
      }) => <EditProgramFormModal id={id} goBack={goBack} program={program} />}
    />
    <Route
      path="/programs/:id/info"
      component={({
        location: {
          state: { program },
        },
        history: { goBack, push },
        match: {
          params: { id },
        },
      }) => (
        <ProgramInfoFormModal
          id={id}
          goBack={goBack}
          program={program}
          routeEditProgram={() => {
            push({
              pathname: `/programs/${program.id}/edit`,
              state: {
                program,
                isModalOpen: true,
              },
            });
          }}
        />
      )}
    />
    <Route
      path="/member/:id/dialysis/create"
      component={({
        history: { goBack },
        match: {
          params: { id },
        },
      }) => (
        <CreateDialysisApptFormModal
          title="Create new dialysis appointment"
          memberId={id}
          cancel={goBack}
        />
      )}
    />
    <Route
      path="/member/:memberId/dialysis/:dialysisId/delete"
      component={({
        history: { goBack },
        match: {
          params: { dialysisId, memberId },
        },
      }) => (
        <DeleteDialysisApptFormModal dialysisId={dialysisId} cancel={goBack} memberId={memberId} />
      )}
    />
  </Switch>
);
