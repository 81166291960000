import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { message as alert } from "antd";
import {
  DialysisAppointmentSchedulesDocument,
  useCreateDialysisMutation,
} from "hooks/graphql";
import CreateDialysisApptForm, {
  CreateDialysisApptFormInputs,
} from "components/CreateDialysisApptForm";

export interface CreateDialysisApptFormModalProps {
  cancel: () => void;
  title?: React.ReactNode;
  memberId: string;
}

export const CreateDialysisApptFormModal: React.FC<
  CreateDialysisApptFormModalProps
> = ({ cancel, title, memberId }: CreateDialysisApptFormModalProps) => {
  const formRef = useRef(null);

  const [createDialysis, { loading }] = useCreateDialysisMutation({
    onCompleted: cancel,
    onError: ({ message }) => alert.error(message),
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: DialysisAppointmentSchedulesDocument, variables: { memberId } },
    ],
  });

  const submit = async (props: CreateDialysisApptFormInputs) => {
    await createDialysis({
      variables: {
        input: {
          startDate: props.startDate.format("YYYY-MM-DD"),
          endDate: props.endDate.format("YYYY-MM-DD"),
          time: props.time.format("HH:mm"),
          memberId,
        },
      },
    });
  };

  return (
    <Modal
      title={title}
      okText="Submit"
      loading={loading}
      submissionLoading={loading}
      onCancel={cancel}
      width={700}
      onOk={async () => submit(await formRef.current.getDialysisAppt())}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <CreateDialysisApptForm ref={formRef} />
    </Modal>
  );
};

export default CreateDialysisApptFormModal;
