/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import {
  MemberProfile,
  TestPatient,
  StatusChange,
} from "containers/MemberProfile";
import ModalSwitch from "routers/ModalSwitch";
import ProspectUpload from "containers/ProspectUpload";
import Prospect from "containers/Prospect";
import EnrollmentForm from "containers/Enrollment";
import ProspectEnrollmentForm from "containers/ProspectEnrollment";
import { privilegeForString } from "constants/adminPrivilege";
import CreateProspectForm from "containers/CreateProspectForm";
import { TaskCalendarEvent } from "containers/MemberProfile/components/TaskCalendar";
import { usePageView } from "hooks/usePageView";
import { useCurrentAdminQuery } from "hooks/graphql";
import { ManageMembers } from "containers/ManageMembers";
import { HeaderContainer } from "containers/Header";
import { isCallCenterAdmin } from "utils/roles";
import UnauthorizedPage from "components/Unauthorized";
import { ManageBonusBehaviorSubmissions } from "containers/ManageBonusBehaviorSubmissions";
import SmsOutreach from "containers/SmsOutreach";
import { PauseStatus } from "types/globalTypes";
import { ManageBonusBehaviors } from "containers/ManageBonusBehaviors";
import { ManagePrograms } from "containers/ManagePrograms";
import { Program } from "components/ManagePrograms";
import { AdvancedSearch } from "containers/AdvancedSearch";

export interface Props {
  signOut: () => void;
}

export interface RouterState {
  isModalOpen?: boolean;
}

const AppRouter: React.FC<Props> = ({ signOut }: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push: navigate } = useHistory();
  const location = usePageView<RouterState>();
  const [previousLocation, setPreviousLocation] = useState(location);
  const {
    data: {
      currentAdmin: {
        id: adminId = null,
        firstName = "",
        lastName = "",
        adminRoleByAdminRoleId: { name: adminRole = "" } = {},
      } = {},
    } = {},
    loading,
  } = useCurrentAdminQuery();

  useEffect(
    () => {
      if (!location.state || !location.state.isModalOpen) {
        setPreviousLocation(location);
      }
    },
    [location],
  );

  const isModal = !!(location.state && location.state.isModalOpen);

  if (loading) return null;

  const adminPrivilege = privilegeForString(adminRole);
  if (!adminPrivilege || !adminId) {
    signOut();
    return null;
  }

  return (
    <div>
      <IdleTimer ref={null} onIdle={() => signOut()} timeout={900000} />
      <HeaderContainer
        signOut={signOut}
        adminRole={adminPrivilege}
        adminName={`${firstName} ${lastName}`}
        routeMemberPage={(memberId) =>
          navigate({ pathname: `/member/${memberId}` })
        }
        routeProspectPage={(prospectId) =>
          navigate({
            pathname: `/prospect/${prospectId}`,
          })
        }
        routeRoot={() =>
          navigate({
            pathname: "/",
          })
        }
        routeEnroll={() => {
          navigate({ pathname: "/enroll" });
        }}
        routeAdvancedSearch={() => {
          navigate({ pathname: "/advancedSearch" });
        }}
      />
      <Switch location={isModal ? previousLocation : location}>
        <Route
          exact
          path="/acquisition/prospect-upload"
          component={ProspectUpload}
        />
        <Route
          exact
          path="/acquisition/create-prospect"
          component={({ history }) => (
            <CreateProspectForm
              title="Create New Prospect"
              routeToProspectPage={({ id }) =>
                history.push({ pathname: `/prospect/${id}` })
              }
              adminRole={adminPrivilege}
            />
          )}
        />
        <Route
          exact
          path="/acquisition/sms-outreach"
          component={(props) =>
            !isCallCenterAdmin(adminPrivilege) ? (
              <SmsOutreach {...props} />
            ) : (
              <UnauthorizedPage />
            )
          }
        />
        <Route
          exact
          path="/advancedSearch"
          component={({ history }) => (
            <AdvancedSearch
              routeMemberPage={(memberId) =>
                navigate({ pathname: `/member/${memberId}` })
              }
              routeProspectPage={(prospectId) =>
                navigate({
                  pathname: `/prospect/${prospectId}`,
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/enroll"
          component={({ history }) => (
            <EnrollmentForm
              title="Enroll New Member"
              adminRole={adminPrivilege}
              activeIdentityTags={[]}
              routeMemberProfile={(memberId) =>
                history.push({
                  pathname: `/member/${memberId}`,
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/prospect/:id/enroll"
          component={({
            history: { push },
            match: {
              params: { id },
            },
          }) => (
            <ProspectEnrollmentForm
              adminRole={adminPrivilege}
              id={id}
              routeMemberProfile={(memberId) =>
                push({
                  pathname: `/member/${memberId}`,
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/prospect/:id"
          component={({
            history,
            match: {
              params: { id },
            },
          }) => (
            <Prospect
              id={id}
              adminPrivilege={adminPrivilege}
              routeEnrollment={(prospectId: string) =>
                history.push({
                  pathname: `/prospect/${prospectId}/enroll`,
                })
              }
              routeProspectInformation={(prospectId: string) => {
                history.push({
                  pathname: `/prospect/${prospectId}/info`,
                  state: {
                    id,
                    isModalOpen: true,
                    adminRole,
                  },
                });
              }}
              routeProspectStatus={(
                prospectId,
                shortId,
                programCode,
                status,
              ) => {
                history.push({
                  pathname: `/prospect/${prospectId}/actions/status`,
                  state: {
                    isModalOpen: true,
                    prospect: {
                      id,
                      status,
                      programCode,
                      shortId,
                    },
                    adminRole,
                  },
                });
              }}
              routeReOpenProspect={(programCode: string, shortId: string) => {
                history.push({
                  pathname: `/prospect/${id}/actions/reopen`,
                  state: {
                    id,
                    programCode,
                    shortId,
                    userType: "prospect",
                    isModalOpen: true,
                  },
                });
              }}
            />
          )}
        />
        <Route
          exact
          path="/manage/members"
          component={({ goBack, history }) => (
            <ManageMembers
              goBack={goBack}
              routeToMember={({ id }) =>
                history.push({
                  pathname: `/member/${id}`,
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/submissions/behaviors/review"
          component={({ history }) => (
            <ManageBonusBehaviorSubmissions
              routeToMember={(id: string) =>
                history.push({
                  pathname: `/member/${id}`,
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/manage/bonusBehaviors"
          component={({ history }) => (
            <ManageBonusBehaviors
              routeToBonusBehaviorModal={(allBonusBehaviorConfigs) =>
                history.push({
                  pathname: "/create/bonus",
                  state: {
                    isModalOpen: true,
                    allBonusBehaviorConfigs,
                  },
                })
              }
            />
          )}
        />
        <Route
          exact
          path="/manage/programs"
          component={({ history }) => (
            <ManagePrograms
              routeEditProgram={(program: Program) => {
                history.push({
                  pathname: `/programs/${program.id}/edit`,
                  state: {
                    program,
                    isModalOpen: true,
                  },
                });
              }}
              routeProgramModal={(program: Program) => {
                history.push({
                  pathname: `/programs/${program.id}/info`,
                  state: {
                    program,
                    isModalOpen: true,
                  },
                });
              }}
            />
          )}
        />
        <Route
          exact
          path="/member/:id"
          component={({
            history,
            match: {
              params: { id },
            },
          }) => (
            <MemberProfile
              id={id}
              adminId={adminId}
              adminRole={adminPrivilege}
              routeCheckIn={(
                calendarData: TaskCalendarEvent,
                memberTimezone,
              ) => {
                history.push({
                  pathname: `/member/${id}/check-in`,
                  state: {
                    id,
                    calendarData,
                    isModalOpen: true,
                    timezone: memberTimezone,
                  },
                });
              }}
              routeAppointment={(calendarData, memberTimezone) => {
                history.push({
                  pathname: `/member/${id}/appointment/${
                    calendarData.appointmentId
                  }`,
                  state: {
                    id,
                    timezone: memberTimezone,
                    calendarData,
                    isModalOpen: true,
                  },
                });
              }}
              routeTestPatient={({
                isReminderMessagingEnabled,
                isLocalNotificationEnabled,
                isProductTourRecurring,
              }: TestPatient) => {
                history.push({
                  pathname: `/member/${id}/test`,
                  state: {
                    id,
                    isReminderMessagingEnabled,
                    isLocalNotificationEnabled,
                    isProductTourRecurring,
                    isModalOpen: true,
                  },
                });
              }}
              routeStatusChange={({
                shortId,
                programCode,
                status,
              }: StatusChange) => {
                history.push({
                  pathname: `/member/${id}/actions/status`,
                  state: {
                    id,
                    isModalOpen: true,
                    shortId,
                    programCode,
                    status,
                    adminRole,
                  },
                });
              }}
              routeDeleteMember={() => {
                history.push({
                  pathname: `/member/${id}/actions/delete`,
                  state: {
                    id,
                    isModalOpen: true,
                  },
                });
              }}
              routeInfoModal={() => {
                history.push({
                  pathname: `/member/${id}/information`,
                  state: {
                    isModalOpen: true,
                    id,
                    adminRole,
                  },
                });
              }}
              routeRewardModal={(
                periodEnd: Date,
                periodReward: number,
                periodSum: number,
                isAdmin: boolean,
                rewardPeriodId: string,
                periodStart: Date,
                settleDate: Date,
                pauseStatus: PauseStatus,
                programCode: string,
              ) => {
                history.push({
                  pathname: `/member/${id}/rewards`,
                  state: {
                    id,
                    isModalOpen: true,
                    periodEnd,
                    periodReward,
                    periodSum,
                    isAdmin,
                    rewardPeriodId,
                    periodStart,
                    settleDate,
                    pauseStatus,
                    programCode,
                  },
                });
              }}
              routeCreateDialysisAppointment={() => {
                history.push({
                  pathname: `/member/${id}/dialysis/create`,
                  state: {
                    isModalOpen: true,
                    adminRole,
                  },
                });
              }}
              routeDeleteDialysisAppointment={(dialysisId) => {
                history.push({
                  pathname: `/member/${id}/dialysis/${dialysisId}/delete`,
                  state: {
                    isModalOpen: true,
                    adminRole,
                  },
                });
              }}
              routeCreateReminder={(memberTimezone, programTemplate) => {
                history.push({
                  pathname: `/member/${id}/reminders/create`,
                  state: {
                    isModalOpen: true,
                    timezone: memberTimezone,
                    adminRole,
                    programTemplate,
                  },
                });
              }}
              routeEditReminder={(
                reminderId,
                memberTimezone,
                programTemplate,
              ) => {
                history.push({
                  pathname: `/member/${id}/reminders/${reminderId}/edit`,
                  state: {
                    isModalOpen: true,
                    timezone: memberTimezone,
                    adminRole,
                    programTemplate,
                  },
                });
              }}
              routeReminderInfo={(
                reminderId,
                memberTimezone,
                programTemplate,
              ) => {
                history.push({
                  pathname: `/member/${id}/reminders/${reminderId}/info`,
                  state: {
                    isModalOpen: true,
                    timezone: memberTimezone,
                    adminRole,
                    programTemplate,
                  },
                });
              }}
              routePauseModal={(pauseStatus: string) => {
                history.push({
                  pathname: `/member/${id}/other`,
                  state: {
                    isModalOpen: true,
                    pauseStatus,
                  },
                });
              }}
              routeEditPauseModal={(pauseDetails) => {
                history.push({
                  pathname: `/member/${id}/edit/pause`,
                  state: {
                    isModalOpen: true,
                    pauseDetails,
                  },
                });
              }}
              routeReOpenProspect={(programCode: string, shortId: string) => {
                history.push({
                  pathname: `/member/${id}/actions/reopen`,
                  state: {
                    id,
                    programCode,
                    shortId,
                    userType: "member",
                    isModalOpen: true,
                  },
                });
              }}
            />
          )}
        />
        {/* <Route
          exact
          path="/manage/customers"
          render={(props) =>
            isAdminOrSuperAdmin(adminPrivilege) ? (
              <Customers {...props} />
            ) : (
              <UnauthorizedPage />
            )
          }
        />
        <Route
          exact
          path="/manage/customers/:id"
          render={() =>
            isAdminOrSuperAdmin(adminPrivilege) ? (
              <EditCustomer />
            ) : (
              <UnauthorizedPage />
            )
          }
        /> */}
      </Switch>
      {isModal ? <Route component={() => <ModalSwitch />} /> : null}
    </div>
  );
};

export default AppRouter;
