import * as React from "react";
import moment from "moment-timezone";
import { Timeline } from "antd";
import { isEmpty } from "lodash";
import { DATE_TIME_FORMAT } from "utils/date";

const BOLD_STYLE: React.CSSProperties = { fontWeight: "bold" };
const LIGHTER_STYLE: React.CSSProperties = {
  fontWeight: "lighter",
  fontStyle: "italic",
};

const STYLE_CHECKIN_SUBMITTED: React.CSSProperties = { color: "#199473" };
const STYLE_CHECKIN_SUCCESSFUL: React.CSSProperties = { color: "#F35627" };
const STYLE_WIFI: React.CSSProperties = { color: "#0967D2" };
const STYLE_CELL: React.CSSProperties = { color: "#BA3AE7" };
const STYLE_UNKNOWN: React.CSSProperties = { color: "#FF0000" };
const STYLE_HIGHLIGHT_TITLE: React.CSSProperties = { color: "#04C0D1" };
const STYLE_HIGHLIGHT_TYPE: React.CSSProperties = { color: "#FF59E6" };

const DATE_FORMAT = "MMM Do YYYY";

export type TimelineEvent = {
  id: string;
  name: string;
  timestamp: string;
  properties: string;
};

interface ActivityTimelineProps {
  events: TimelineEvent[];
  timezone: string;
}

// event Names
const MEMBER_PAUSED = "Member Paused";
const MEMBER_UNPAUSED = "Member Unpaused";
const PAYMENT_SUCESS = "PaymentSucess";
const MEMBER_ACTIVATION = "MemberActivation";
const MEMBER_STATUS_CHANGE = "Member Status Change";
const PRIMARY_EMAIL_UPDATED = "Primary Email Updated";
const PRIMARY_ADDRESS_UPDATED = "Primary Address Updated";
const IDENTITY_TAG_ADDED = "Identity Tag Added";
const IDENTITY_TAG_REMOVED = "Identity Tag Removed";
const BONUS_BEHAVIOR_APPROVED = "Bonus Behavior Approved";
const BONUS_BEHAVIOR_REJECTED = "Bonus Behavior Rejected";
const CHECK_IN_CREDITED = "Check In Credited";
const HIGHLIGHT_SENT = "Highlight Sent";
const HIGHLIGHT_SURVEY_RESPONSE = "Highlight survey response";
const HIGHLIGHT_INTERACTION = "Highlight Interaction";
const HIGHLIGHT_DISPLAYED = "Highlight Displayed";
const INTERACTION = "Interaction";
const ALERT_CARD = "Alert Card";
const NAVIGATION = "Navigation";
const CHECK_IN_RECEIVED_APP = "Check In Successfully Received - App";
const CHECK_IN_RECEIVED_SERVER = '"Check In Successfully Received - Server"';
const CHECK_IN_SUBMITTED = "Check In Submitted";
const ERROR = "Error";
const ENROLLMENT_PERMISSION_CHANGED_NOTIFICATION =
  "Enrollment Permission Changed - Notification";
const ENROLLMENT_PERMISSINO_CHANGED_CAMERA =
  "Enrollment Permission Changed - Camera";
const IDENTIFY = "$identify";

const READABLE_EVENT = {
  [MEMBER_PAUSED]: "Paused due to <last_pause_reason>",
  [MEMBER_UNPAUSED]: "Unpaused",
  [PAYMENT_SUCESS]: "Successful Payment of $<amount>",
  [MEMBER_ACTIVATION]: "Activated",
  [MEMBER_STATUS_CHANGE]: "Status Changed to <To> by <Admin>",
  [PRIMARY_EMAIL_UPDATED]: "Primary Email Updated by <Actor>",
  [PRIMARY_ADDRESS_UPDATED]: "Primary Address Updated by <Actor>",
  [IDENTITY_TAG_ADDED]: "Identity Tag <Tag Name> Added",
  [IDENTITY_TAG_REMOVED]: "Identity Tag <Tag Name> Removed",
  [BONUS_BEHAVIOR_APPROVED]: "Bonus Behavior <Bonus Name> Approved",
  [BONUS_BEHAVIOR_REJECTED]: "Bonus Behavior <Bonus Name> Rejected",
  [CHECK_IN_CREDITED]:
    "Check In Credited for <Reminder Type> Reminder due to <Credited Reason>",
  [HIGHLIGHT_SENT]: "Highlight <Highlight Name> Highlight Sent",
  [HIGHLIGHT_SURVEY_RESPONSE]:
    "<Highlight Name> Highlight survey response was <Survey Response>",
  [HIGHLIGHT_INTERACTION]: "Highlight Interaction was <target>",
  [HIGHLIGHT_DISPLAYED]:
    "Highlight <Highlight Name> was displayed with Title: <Highlight Title> and Type: <Highlight Type>",
  [INTERACTION]: "Interaction type <type> on <target>",
  [ALERT_CARD]: "Alert Card displayed showing <title>",
  [NAVIGATION]: "Navigate TO <to> FROM <from>",
  [CHECK_IN_RECEIVED_APP]:
    "Check In Successfully Received on App for reminder type <Reminder Type> via <Network Connection Type>",
  [CHECK_IN_RECEIVED_SERVER]:
    "Check In Successfully Received - Server for reminder type <Reminder Type>",
  [CHECK_IN_SUBMITTED]: "Check In Submitted for reminder type <Reminder Type>",
  [ERROR]: "Error: <message>",
  [ENROLLMENT_PERMISSION_CHANGED_NOTIFICATION]:
    "Enrollment Permission Changed - Notification to <State>",
  [ENROLLMENT_PERMISSINO_CHANGED_CAMERA]:
    "Enrollment Permission Changed - Camera to <State>",
  [IDENTIFY]: "Identify",
};

const replaceStringValuesJSX = (str: string, replaceMap: any, name: string) => {
  if (isEmpty(replaceMap)) {
    return str;
  }

  const {
    "Reminder Type": reminder = "unknown",
    "Network Connection Type": connection = "unknown",
    "Highlight Name": highlight_name = "unknown",
    "Highlight Type": highlight_type = "unknown",
    "Highlight Title": highlight_title = "unknown",
  } = replaceMap;

  if (name === CHECK_IN_SUBMITTED) {
    return (
      <>
        <span style={STYLE_CHECKIN_SUBMITTED}>Check In Submitted</span> for
        reminder type {reminder}
      </>
    );
  }

  if (name === CHECK_IN_RECEIVED_APP) {
    let style = STYLE_UNKNOWN;
    if (connection.toLowerCase() === "wifi") {
      style = STYLE_WIFI;
    } else if (connection.toLowerCase() === "cellular") {
      style = STYLE_CELL;
    }
    return (
      <>
        <span style={STYLE_CHECKIN_SUCCESSFUL}>
          Check In Successfully Received
        </span>{" "}
        on App for reminder type {reminder} via
        <span style={style}> {connection}</span>
      </>
    );
  }

  if (name === CHECK_IN_RECEIVED_SERVER) {
    return (
      <>
        <span style={STYLE_CHECKIN_SUCCESSFUL}>
          Check In Successfully Received
        </span>{" "}
        - Server for reminder type {reminder.toUpperCase()}
      </>
    );
  }
  if (name === HIGHLIGHT_SENT) {
    return (
      <>
        <span>
          Highlight {highlight_name} was sent with
          <span style={STYLE_HIGHLIGHT_TITLE}> Title: {highlight_title} </span>
          and
          <span style={STYLE_HIGHLIGHT_TYPE}> Type: {highlight_type}</span>
        </span>
      </>
    );
  }
  if (name === HIGHLIGHT_DISPLAYED) {
    return (
      <>
        <span>
          Highlight {highlight_name} was displayed with
          <span style={STYLE_HIGHLIGHT_TITLE}> Title: {highlight_title} </span>
          and
          <span style={STYLE_HIGHLIGHT_TYPE}> Type: {highlight_type}</span>
        </span>
      </>
    );
  }

  const regex = new RegExp(
    Object.keys(replaceMap)
      .map((value) => `<${value}>`)
      .join("|"),
    "gi",
  );

  const content = str.replace(regex, (matched) => {
    const replacement = replaceMap[matched.slice(1, -1)];
    return replacement || replacement === 0 ? replacement : "";
  });

  return <span>{content}</span>;
};

const ActivityTimeline: React.FC<ActivityTimelineProps> = ({
  events,
  timezone,
}) => {
  const hasEvents = events.length > 0;

  return hasEvents ? (
    <Timeline>
      {events.map(({ id, name, timestamp, properties }) => {
        if (name === "DATE") {
          return (
            <Timeline.Item
              key={id}
              color="grey"
              dot={moment(timestamp)
                .tz("UTC")
                .format(DATE_FORMAT)}
            >
              <div style={BOLD_STYLE}>&nbsp;</div>
            </Timeline.Item>
          );
        }
        return (
          <Timeline.Item key={id} color="#BFBFBF">
            <div style={BOLD_STYLE}>
              {replaceStringValuesJSX(
                READABLE_EVENT[name] || name,
                JSON.parse(properties),
                name,
              )}
            </div>
            <div style={LIGHTER_STYLE}>
              {moment(timestamp)
                .tz(timezone)
                .format(DATE_TIME_FORMAT)}
            </div>
          </Timeline.Item>
        );
      })}
    </Timeline>
  ) : (
    <div>NO EVENTS YET</div>
  );
};

export default ActivityTimeline;
