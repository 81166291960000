import React from "react";
import { Card } from "@wellth/web-ui";
import {
  nonRelevantConditionText,
  useConditionsByExternalIdProgramCode,
} from "hooks/useConditions";
import CARD_STYLES from "../styles";
import styles from "./styles";
import { ConditionCardProps, ConditionsProps, ConditionProps } from "./types";

const MemberCondition: React.FC<ConditionProps> = ({ name }) => <>{name}</>;

export const Conditions: React.FC<ConditionsProps> = ({ conditionNames }) => (
  <>
    <ul style={styles.listStyle}>
      {conditionNames.size > 0 ? (
        Array.from(conditionNames).map((conditionName, index) => (
          <li key={`condition-${index}`} style={styles.listItemStyle}>
            <MemberCondition name={conditionName} />
          </li>
        ))
      ) : (
        <li>{nonRelevantConditionText}</li>
      )}
    </ul>
  </>
);

export const ConditionsCard: React.FC<ConditionCardProps> = ({
  externalId,
  programCode,
}) => {
  const { conditionNames, error } = useConditionsByExternalIdProgramCode(
    externalId,
    programCode,
  );

  return (
    <Card
      headStyle={CARD_STYLES.head}
      bodyStyle={CARD_STYLES.body}
      style={CARD_STYLES.card}
      title="Conditions"
    >
      {error !== null ? (
        <div>{`Error fetching conditions: ${error}`}</div>
      ) : (
        <Conditions conditionNames={conditionNames} />
      )}
    </Card>
  );
};

export default ConditionsCard;
