import React from "react";
import {
  DialysisAppointments as AppointmentsUI,
  DialysisAppointmentsProps,
} from "components/DialysisAppointments";

export const DialysisAppointments: React.FC<DialysisAppointmentsProps> = ({
  onDialysisApptModalClick,
  appointments,
}) => (
  <AppointmentsUI
    onDialysisApptModalClick={onDialysisApptModalClick}
    appointments={appointments}
  />
);

export default DialysisAppointments;
