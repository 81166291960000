import React, { useImperativeHandle } from "react";
import { Form, FormInputType } from "@wellth/web-ui";
import moment, { Moment } from "moment-timezone";
import { dayIsInThePast } from "utils/date";

const FormInputs = {
  DAY_OF_WEEK: "dayOfWeek",
  TIME: "time",
  SCHEDULE_JOB: "scheduleJobId",
  DELETE_AFTER: "deleteAfter",
};

export type DeleteDialysisApptFormProps = {
  scheduleJob: string;
  time: string;
  dayOfWeek: string;
  startDate: string;
  endDate: string;
};

export type DeleteDialysisApptFormInputs = {
  scheduleJobId: string;
  deleteAfter: Moment;
};

const shouldPreventPastDates = (current: Date, timezone: string) =>
  current && dayIsInThePast(current, timezone);

const DeleteDialysisApptForm: React.RefForwardingComponent<
  any,
  DeleteDialysisApptFormProps
> = (props, ref) => {
  const formRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    getDialysisAppt: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      wrappedComponentRef={formRef}
      mapPropsToFields={(_props, createFormField) => ({
        time: createFormField({ value: props.time }),
        scheduleJobId: createFormField({ value: props.scheduleJob }),
        dayOfWeek: createFormField({ value: props.dayOfWeek }),
      })}
      inputs={[
        {
          type: FormInputType.Text,
          title: "Day of Week",
          key: FormInputs.DAY_OF_WEEK,
          readOnly: true,
          disabled: true,
        },
        {
          type: FormInputType.Text,
          title: "Time",
          key: FormInputs.TIME,
          readOnly: true,
          disabled: true,
        },
        {
          type: FormInputType.Info,
          title: "Schedule Id",
          key: FormInputs.SCHEDULE_JOB,
        },
        {
          type: FormInputType.Date,
          format: "LL",
          title: "End Date (Remove appointments on this day and forward)",
          key: FormInputs.DELETE_AFTER,
          decoratorOptions: {
            rules: [
              { required: true, message: "End date is required" },
            ],
          },
          disabledDate: (current) =>
            shouldPreventPastDates(current.toDate(), moment.tz.guess()),
        },
      ]}
    />
  );
};

export default React.forwardRef(DeleteDialysisApptForm);
