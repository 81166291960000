import React from "react";
import {Card, Icon} from "@wellth/web-ui";
import moment from "moment-timezone";
import { TIME_FORMAT } from "utils/date";
import {DialysisAppointmentScheduleSummary} from "types/globalTypes";
import COLORS from "utils/colors";
import CARD_STYLES from "../styles";

export enum DialysisMethod {
  Create = "create",
  Delete = "delete",
}

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  fontSize: "1.125rem",
  color: "#1f2226",
  fontWeight: "bold",

};

const ICON_SIZE: React.CSSProperties = {
  fontSize: "1.25rem",
};

const CATEGORY_STYLE: React.CSSProperties = {
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#667580",
};

const NO_APPT_STYLE: React.CSSProperties = {
  ...CATEGORY_STYLE,
}

const TIME_STYLE: React.CSSProperties = {
  marginRight: "0.25rem",
  fontSize: "1.125rem",
};

const CONTAINER_STYLE: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginBottom: "0.5rem",
  justifyContent: "space-between",
};

const CONTENT_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const EDIT_STYLE: React.CSSProperties = {
  marginLeft: "auto",
  marginRight: "0.5rem",
  color: COLORS.midBlue,
  fontSize: "0.75rem",
  fontWeight: "normal",
  cursor: "pointer",
};

interface DialysisModalClickProps {
  dialysisId?: string;
  method: string;
}

export interface DialysisAppointmentsProps {
  appointments: DialysisAppointmentScheduleSummary[];
  onDialysisApptModalClick: ({dialysisId, method}: DialysisModalClickProps) => void;
}
export interface DialysisAppointmentProps {
  appointment: DialysisAppointmentScheduleSummary;
  onDialysisApptModalClick: ({dialysisId, method}: DialysisModalClickProps) => void;
}

const DialysisAppointment = ({ appointment, onDialysisApptModalClick }: DialysisAppointmentProps) => (
  <div style={CONTAINER_STYLE}>
    <div style={CONTENT_STYLE}>
      <span style={CATEGORY_STYLE}>Dialysis</span>
      <div>
        <span style={TIME_STYLE}>
          {moment(appointment.time, TIME_FORMAT).format(TIME_FORMAT)}
        </span>
        <span style={CATEGORY_STYLE}>
          every <span style={TIME_STYLE}>{appointment.dayOfWeek}</span>  ({moment(appointment.startDate).format("MM/DD/YYYY")} - {moment(appointment.endDate).format("MM/DD/YYYY")})
        </span>
      </div>
    </div>
    <span style={EDIT_STYLE} onClick={() => onDialysisApptModalClick({dialysisId: appointment.scheduleJob, method: DialysisMethod.Delete})}>Edit</span>
  </div>
);

export const DialysisAppointments: React.FC<DialysisAppointmentsProps> = ({
  appointments,
  onDialysisApptModalClick,
}) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={BODY_STYLE}
    style={CARD_STYLES.card}
    title="Dialysis Appointments"
    extra={
      <Icon
        type="plus-circle"
        theme="filled"
        style={ICON_SIZE}
        onClick={() => onDialysisApptModalClick({ method: DialysisMethod.Create })}
      />
    }
  >
    {/* eslint-disable-next-line prettier/prettier */}
    {appointments?.length ? appointments.map((appt: DialysisAppointmentScheduleSummary) => (
      <DialysisAppointment appointment={appt} onDialysisApptModalClick={onDialysisApptModalClick} key={appt.scheduleJob} />
    )) : <span style={NO_APPT_STYLE}>No Appointments</span>}

  </Card>
);

export default DialysisAppointments;
