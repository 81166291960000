import { useMemo } from "react";
import { ProgramsConditions } from "components/Conditions/types";
import useApiResult from "../services/index";
import { getConditionsByExternalIdProgramCode } from "../services/conditionsService";

// if adding authorized conditions, add in both objects
export const AUTHORIZED_CONDITION_READABLE_TEXT = {
  END005: "Diabetes (Type 2)",
  CIR007: "Hypertension",
};

export const nonRelevantConditionText = `Prospect is not confirmed to have the following conditions: ${Object.values(
  AUTHORIZED_CONDITION_READABLE_TEXT,
).join(", ")}; but may have these and other relevant conditions`;

const AUTHORIZED_CONDITIONS = [
  "END005", // DIABETES_TYPE_2
  "CIR007", // HYPERTENSION
];

export const formatConditionNames = (
  programConditions: ProgramsConditions[],
): Set<string> => {
  const retrievedConditionNames: string[] = [];
  programConditions.forEach((programCondition) => {
    programCondition.conditions.forEach((condition) => {
      if (AUTHORIZED_CONDITIONS.includes(condition.wellthcondition.id)) {
        retrievedConditionNames.push(condition.wellthcondition.name);
      }
    });
  });

  return new Set(retrievedConditionNames);
};

export const useConditionsByExternalIdProgramCode = (
  externalId: string,
  programCode: string,
): { conditionNames: Set<string>; error: string | null } => {
  const request = useMemo(
    () => getConditionsByExternalIdProgramCode(externalId, programCode),
    [externalId, programCode],
  );

  const [result, error] = <[ProgramsConditions[], string | null]>(
    useApiResult(request)
  );

  let retrievedConditionNames: Set<string> = new Set();
  if (result) {
    retrievedConditionNames = formatConditionNames(result);
  }

  return { conditionNames: retrievedConditionNames, error };
};

const useConditions = {
  useConditionsByExternalIdProgramCode,
};

export default useConditions;
