import React, { useRef } from "react";
import { Modal } from "components/Modal";
import { message as alert } from "antd";
import {
  DialysisAppointmentSchedulesDocument,
  useDeleteDialysisMutation,
  useDialysisAppointmentScheduleQuery,
} from "hooks/graphql";
import DeleteDialysisApptForm, {
  DeleteDialysisApptFormInputs,
} from "components/DeleteDialysisApptForm";

export interface DeleteDialysisApptFormModalProps {
  cancel: () => void;
  dialysisId: string;
  memberId: string;
}

export const DeleteDialysisApptFormModal: React.FC<
  DeleteDialysisApptFormModalProps
> = ({ cancel, dialysisId, memberId }: DeleteDialysisApptFormModalProps) => {
  const formRef = useRef(null);

  const {
    loading,
    data: {
      dialysisAppointmentSchedule: {
        scheduleJob,
        time,
        dayOfWeek,
        startDate,
        endDate,
    } = {} } = {},
  } = useDialysisAppointmentScheduleQuery({
    onError: ({ message }) => alert.error(message),
    variables: {
      scheduleJob: dialysisId,
    },
  });

  const [
    deleteDialysis,
    { loading: submissionLoading },
  ] = useDeleteDialysisMutation({
    onCompleted: cancel,
    onError: ({ message }) => alert.error(message),
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: DialysisAppointmentSchedulesDocument, variables: { memberId } },
    ],
  });

  const submit = async (props: DeleteDialysisApptFormInputs) => {
    await deleteDialysis({
      variables: {
        input: {
          scheduleJob: props.scheduleJobId,
          fromDate: props.deleteAfter.format("YYYY-MM-DD"),
        },
      },
    });
  };

  return (
    <Modal
      title="Edit Dialysis Appointment"
      okText="Submit"
      loading={loading}
      submissionLoading={submissionLoading}
      onCancel={cancel}
      width={700}
      onOk={async () => submit(await formRef.current.getDialysisAppt())}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <DeleteDialysisApptForm
        ref={formRef}
        scheduleJob={scheduleJob}
        time={time}
        dayOfWeek={dayOfWeek}
        startDate={startDate}
        endDate={endDate}
      />
    </Modal>
  );
};

export default DeleteDialysisApptFormModal;
